class UserInfo {
  user;
  constructor() {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  getUserName() {
    return this.user?.name;
  }

  getUserEmail() {
    return this.user?.email;
  }

  getUserRole() {
    return this.user?.role;
  }

  getUserInfo() {
    this.user = JSON.parse(localStorage.getItem("user"));
  }
}

export default new UserInfo();
