import BaseController from "./baseController";

export default class {
  urlBase = "/projects";

  constructor() {
    this.base = BaseController;
  }

  async registerProject(body) {
    return this.base._post(`${this.urlBase}`, body);
  }

  async getProjects() {
    return this.base._get(`${this.urlBase}`);
  }

  async getProject(id) {
    return this.base._get(`${this.urlBase}/${id}`);
  }

  async updateProject(id, body) {
    return this.base._patch(`${this.urlBase}/${id}`, body);
  }

  async deleteProject(id) {
    return this.base._delete(`${this.urlBase}/${id}`);
  }
}
