export default {
    badge: 'Insígnia',
    close: 'Fechar',
    dataIterator: {
      noResultsText: 'Nenhum registro correspondente encontrado',
      loadingText: 'Carregando...',
    },
    dataTable: {
      itemsPerPageText: 'Linhas por página:',
      ariaLabel: {
        sortDescending: 'Ordenado em ordem decrescente.',
        sortAscending: 'Ordenado em ordem crescente.',
        sortNone: 'Não ordenado.',
        activateNone: 'Ativar para remover a ordenação.',
        activateDescending: 'Ativar para ordenar em ordem decrescente.',
        activateAscending: 'Ativar para ordenar em ordem crescente.',
      },
      sortBy: 'Ordenar por',
    },
    dataFooter: {
      itemsPerPageText: 'Itens por página:',
      itemsPerPageAll: 'Tudo',
      nextPage: 'Próxima página',
      prevPage: 'Página anterior',
      firstPage: 'Primeira página',
      lastPage: 'Última página',
      pageText: '{0}-{1} de {2}',
    },
    datePicker: {
      itemsSelected: '{0} selecionados',
      nextMonthAriaLabel: 'Próximo mês',
      nextYearAriaLabel: 'Próximo ano',
      prevMonthAriaLabel: 'Mês anterior',
      prevYearAriaLabel: 'Ano anterior',
    },
    noDataText: 'Não há dados disponíveis',
    carousel: {
      prev: 'Visual anterior',
      next: 'Próximo visual',
      ariaLabel: {
        delimiter: 'Slide {0} de {1} do carrossel',
      },
    },
    calendar: {
      moreEvents: '{0} mais',
    },
    input: {
      clear: 'Limpar {0}',
      prependAction: 'Ação anexada {0}',
      appendAction: 'Ação anexada {0}',
    },
    fileInput: {
      counter: '{0} arquivos',
      counterSize: '{0} arquivos ({1} no total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        root: 'Navegação da paginação',
        next: 'Próxima página',
        previous: 'Página anterior',
        page: 'Ir para a página {0}',
        currentPage: 'Página {0}, página atual',
        first: 'Primeira página',
        last: 'Última página',
      },
    },
    rating: {
      ariaLabel: {
        item: 'Classificação {0} de {1}',
      },
    },
    loading: 'Carregando...',
  }